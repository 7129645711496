// import React, {useState} from 'react';
import React from 'react';

// import singlefeedback component
import SingleFeedback from './SingleFeedback';

const Feedback = (props) => {
    // useState Hook
    // State takes 2 parameters, a Variable & a Setter function
    // to create State, we use the functions

  // 3.26 destruct the list from props
  const { feedbackList } = props;

//   useState can take an initial

// 3.7 create a function that delete item from the list
// 3.27 change handleDelete so it works
const handleDelete = id => {
  // test that function is called
  console.log(`Handle Delete - id: ${id}`);
  // 3.8 delete method- .filter for arrays
  // .filter will return new array based on a condition
  // const newList = feedbackList.filter(item => item.id !== id);
  // newList will be an array which holds all data minus the particular id
  // 3.9 update State with newList
  // setFeedbackList(newList);
  props.handleDelete(id);
}
  return (
    // we use SingleFeedback component. Pass through feedback list object one at a time
    <div className='container mt-3'>
      <h2 className='text-primary'>Feedback Heading</h2>
      {
        feedbackList.map(feedback => (
            <SingleFeedback key={feedback.id} feedback={feedback} handleDelete={handleDelete}/>
        ))
      }
    </div>
  )
}

export default Feedback
