import React from 'react';

const Footer = () => {
    const date = new Date();  
    // use javascript date to create a date Object
//   const date = new date();
//   we are also going to use String.fromCharCode(169) to output the copyright symbol
// we use date.getFullYear to get current year
  return (
    <div>     
        <p className="navbar navbar-expand-lg navbar-dark bg-success">
          {/* fromCharCode(169) will insert a copyright symbol */}
          {/* { } means its from Javascript */}
          Copyright {String.fromCharCode(169)}
          {/* current year */}
          {' ' + date.getFullYear()} React App
        </p>
    </div>
  )
}

export default Footer;
