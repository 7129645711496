// 3.18 Fragment
// 3.35 add in 2 new hooks, useState, useEffect
// useEffect is a React Lifecycle method
// use it to render when things changed
import React, { useState, useEffect, Fragment } from 'react'
// import a new hook from react-router-dom
// 3.36 import useNavigate hook from router
// this will redirect the browser
import { useParams, useNavigate } from 'react-router-dom';


const EditFeedback = (props) => {
  
  // pull the id of the item we edit from URL 
  const { id } = useParams();
  console.log(`id selected is: ${id}`);

  // 3.36 set up new State
  const [formData, setFormData] = useState ({
    id:'',
    body:'',
    name:'',
    Image:'',
    userId:'',

  })

  // 3.37 make use of useNavigate hook
  const navigate = useNavigate();

  // 3.38 use useEffect hook to get item from feedbackList to edit
  // useEffect is a life cycle method. It takes a callback function, and a dependency array []
  useEffect(() => {
    const item = props.getItem(id);

    // 3.44 set state with the new data
    console.log(item);
    setFormData({
      id: item.id,
      body: item.body,
      image: item.image,
      name: item.name,
      userId: item.userId
    })

    // the dependency array tells the useEffect when it is to run
  }, [id, props]);

  const { name, body, image, userId } = formData; //3.4xx ???

  // in React. Forms control components
  // we need to add in onChange event. So we can update State.
  // 3.15
//   const onChange = e => {
//     // e is event object passed through from the event
//     // update State
//     setFormData({
//       ...formData, [e.target.name]: e.target.value
//     });
//     // ...formData - get everything in the State
//     // e.target.name - get the name from the name attribute in our html
//     // e.target.value - get the value from input field (which part of the formData it will update)
//   }

//3.42
  // in React. Forms control components
  // we need to add in onChange event. So we can update State.
  // 3.15
  // e is the event object passed through from the event
  const onChange = e => {
//     // e is event object passed through from the event
//     // update State
    setFormData({
      ...formData, [e.target.name]: e.target.value
    });
//     // ...formData - get everything in the State
//     // e.target.name - get the name from the name attribute in our html
//     // e.target.value - get the value from input field (which part of the formData it will update)
  }


  // 3.17 create onSubmit()
  const onSubmit = e => {
    // we can stop form from refreshing by
    e.preventDefault();
    // helpful for client-side validation

    // test that onSubmit() is called
    console.log('onSubmit Edit running')
    // 3.43
    console.log(`${id} ${name} ${body} ${image}`)
    // create an update item object.
    const updItem = {
      id,
      body,
      image,
      name,
      userId
    }
    // Check for errors.
    // Send the newContact to an API or to state management
    console.log(updItem);
    // Call our handleUpdate function
    props.handleUpdate(id, updItem);
    // We can do other things after we send off our newContact
    // Redirect the broswer back to the home page ('/')
    navigate('/', { replace: true });

    // check for errors, validate

    // create a newItem to add to feedbackList
    // javascript shorthand id, equals to { id: id } = id,
    // const newItem = {
    //   id,
    //   body,
    //   userId
    // }

    // send newItem to an API or State system

    // console.log(newItem);

    // redirect browser after this.
    
  }

  return (
    // using fragment creates a container to store React components
    // Fragment does not appear in DOM
    // 3.14 code
    <Fragment>
      <h2 className='text-primary'>Edit Feedback</h2>
      <div className='card mb-3'>
        <div className='card-header'>
          Edit Your Feedback
        </div>
        <div className='card-body'>
          {/* 3.17  */}
          <form onSubmit={ e => onSubmit(e)}>
            <div className='mb-3'>
              <label htmlFor='body'>Have your say below:</label>
              <textarea 
                className='form-control'
                id='body'
                placeholder='Your Feedback'
                name='body'
                value={body}
                // 3.16 add onChange event
                onChange={ e => onChange(e)}
              ></textarea>

              {/* 3.41 copied from AddFeedback.js */}
              {/* 3.29 - add new form code */}
              <div className='mb-3'>
                <label htmlFor='name'>Name</label>
                <input 
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Name'
                  name='name'
                  value={name} 
                  onChange={e => onChange(e)}
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='image'>Image</label>
                <input 
                  type='text'
                  className='form-control'
                  id='image'
                  placeholder='URL for image'
                  name='image'
                  value={image} 
                  onChange={e => onChange(e)}
                />
              </div>
              </div>
                <div className='d-grid gap-2'>
                <input type='submit' value='Edit Feedback' className='btn btn-light '/>
              </div>
            </form>
          </div>
        </div>
    </Fragment>
  )
}

export default EditFeedback
