// 1 remove LOGO
// 2 use imr shortcut to import React
// 3.20 import useState
import React, { useState } from 'react';

// 13 import ROUTER to use it
// we can use destructuring to import specific packages/functions/items from a component or package
// we can use 'as' keyword to create an alias for an import
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'

// 3.31 import new img
import joy from './Assets/img/Joy-c.jpg'
import fred from './Assets/img/Fred-c.jpg'
import kristy from './Assets/img/Kristy-c.jpg'

import './App.css';

// 3 import Components (Header & Footer)
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
// 10 import about component
import About from './components/pages/About';
import Feedback from './components/feedback/Feedback';
import AddFeedback from './components/feedback/AddFeedback';
// 3.19 EditFeedback.js
import EditFeedback from './components/feedback/EditFeedback';

// return function can only have one parent 
// 4 add attribute to pass through to a component
function App() {
// add new names of new images
// 3.40 change id: from int to string
  const [feedbackList, setFeedbackList] = useState([
    {
        id: '1', 
        body: "Text goes here",
        image: joy,
        name: 'Joy',
        userId: 1    
    },
    {
        id: '2', 
        body: "Message 1",
        image: fred,
        name: 'Fred',
        userId: 2    
    },
    {
        id: '3', 
        body: "Message 2",
        image: kristy,
        name: 'Kristy',
        userId: 3    
    },
  ]);

  // 3.21 set up Event functions
  // add in new feedback
  const handleAdd = newFeedback => {
    console.log('Add new feedback - App.js', newFeedback);

    // 3.30 add new feedback into feedback list
    setFeedbackList([...feedbackList, newFeedback]);
    // add in current feedbacks with (...feedBackList)
    // add newFeedback into the list

  }

  // 3.22 delete feedback
  const handleDelete = id => {
    console.log('Delete feedback - App.js', id);

    const newList = feedbackList.filter(item => item.id !== id);
    setFeedbackList(newList);
  }

  // 3.23 edit feedback
  const handleUpdate = (id, newItem) => {
    console.log('Update feedback - App.js', id, newItem);

    // 3.41 update the State from the data we get from form
    // use map function to update
    // check if id equals to the one passed through
    // use ternary operator to test and update the part of an array
    const newList = feedbackList.map(item => item.id === id ? {
      id,
      body: newItem.body,
      image: newItem.image,
      name: newItem.name,
      userId: newItem.userId
    } : item);
    setFeedbackList(newList);
  }

  // 3.24 get item from feedback List
  const getItem = (id) => {
    console.log('Get items - App.js', id);
    //  3.39 find item from feedback list
    // use .find array method
    const item = feedbackList.find(listItem => listItem.id === id);
    console.log('Get feedback: ', item);
    // return the item because expect return something back
    return item;
  }

  return (
    <Router>
      <Header branding={'feedbackapp'} />
      <div className='container'>
        {/* 13.2 we can allow the changing of pages by using ROUTES tag */}
        <Routes>
          {/* use Routes to display our components */}
          {/* Route 2 options, path, element */}
          {/* 3.25 modify Routes */}
          <Route path='/' element={<Feedback feedbackList={feedbackList} handleDelete={handleDelete}/>} />
          <Route path='about' element={<About />} />
          <Route path='add' element={<AddFeedback handleAdd={handleAdd}/>} />
          {/* 3.19 - add in feedback route */}
          {/* use /:id to denote dynamic links */}
          <Route path='edit/:id' element={<EditFeedback getItem={getItem} handleUpdate={handleUpdate} />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
