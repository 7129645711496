// use shortcut RAFCE
// this version includes Prop Types

import React from 'react';
import PropTypes from 'prop-types';
// 3.19 import Link component from react-router-dom
import { Link } from 'react-router-dom'
// 3.1 import some icons
import { FaTimes, FaPencilAlt, FaChessKing } from 'react-icons/fa'
// 3.2 import an image
// import joy from '../../Assets/img/Joy-c.jpg'

const SingleFeedback = props => {
    // we can use the props to display the data being passed into this component
    console.log(props)

    // we use destructuring to pull variables out of props
    // instead of props.feedback.id, we use just id
    // const { id, body } = props.feedback
    // const { handle????}

    // 3.5 create onDelete function
    const onDelete = (id) => {
      // test if function is called
      console.log('Delete Clicked');
      // 3.6 test the id that's passed through
      console.log(`ID clicked: ${id}`)
      // 3.7 pass through a function to change feedback.js list so we can actually delete things
      props.handleDelete(id);
    }

    return (
    // 3.3 code html & style
    <div className='card card-body mb-3'>
        <div className='row'>

          <div className='col-4 border border-success'>
            {/* 3.32 add image */}
            <img className='img-fluid pt-2' src={props.feedback.image} alt={props.feedback.name} style={{ maxWidth: 
            '200px', borderRadius: '50%' }} />
            <p>{props.feedback.name}</p>
          </div >

          <div className='col-6 border border-primary'>
            <p>{props.feedback.body}</p>
          </div>

          <div className='col-2 border border-success fs-1 pt-3'>
            {/* Add icons */}
            {/* 3.4 add onClick event & onDelete function */}
            <FaTimes onClick={e=> onDelete(props.feedback.id)} 
            style={{ cursor: 'pointer', float: 'right', color: 'red', marginLeft: '10px' }}/>
            {/* 3.19 add <Link> to allow changing pages */}
            <Link to={`/edit/${props.feedback.id}`}>
              <FaPencilAlt className='text-primary' style={{ cursor: 'pointer', float: 'right' }}/>
            </Link>
          </div>

        </div>
    </div>
  )
}

// 3.10 add id and handleDelete
SingleFeedback.propTypes = {
    body: PropTypes.string,
    id: PropTypes.number,
    handleDelete: PropTypes.func
}

export default SingleFeedback;
