// 3.11 import useState, Fragment
import React, { useState, Fragment } from 'react'

// 3.28 import uuid
import { v4 as uuidv4 } from 'uuid';

const AddFeedback = (props) => {
  // 3.12 create State for form, and set initial values
  const [formData, setFormData] = useState({
    id: 4,
    body: '',
    image: '',
    name: '',
    userId: 1
  });

  // 3.13 use destructuring to pull variable out of State
  // 3.28.1 modify here
  const { body, image, name, userId } = formData;

  // in React. Forms control components
  // we need to add in onChange event. So we can update State.
  // 3.15
  const onChange = e => {
    // e is event object passed through from the event
    // update State
    setFormData({
      ...formData, [e.target.name]: e.target.value
    });
    // ...formData - get everything in the State
    // e.target.name - get the name from the name attribute in our html
    // e.target.value - get the value from input field (which part of the formData it will update)
  }

  // 3.17 create onSubmit()
  const onSubmit = e => {
    // we can stop form from refreshing by
    e.preventDefault();
    // helpful for client-side validation

    // test that onSubmit() is called
    console.log('onSubmit running')

    // check for errors, validate

    // create a newItem to add to feedbackList
    // javascript shorthand id, equals to { id: id } = id,
      // 3.28.1 modify here
    const newItem = {
      id: uuidv4(),
      body,
      image,
      name,
      userId
    }

    // send newItem to an API or State system

    console.log(newItem);

  // 3.28.1 modify here
    props.handleAdd(newItem);

    // redirect browser after this.
    
  }

  return (
    // using fragment creates a container to store React components
    // Fragment does not appear in DOM
    // 3.14 code
    <Fragment>
      <h2 className='text-primary'>Add New Feedback</h2>
      <div className='card mb-3'>
        <div className='card-header'>
          Add Your Feedback
        </div>
        <div className='card-body'>
          {/* 3.17  */}
          <form onSubmit={ e => onSubmit(e)}>
            <div className='mb-3'>
              <label htmlFor='body'>Say something here:</label>
              <textarea 
                className='form-control'
                id='body'
                placeholder='Your Feedback'
                name='body'
                value={body}
                // 3.16 add onChange event
                onChange={ e => onChange(e)}
              ></textarea>
              </div>
              
              {/* 3.29 - add new form code */}
              <div className='mb-3'>
                <label htmlFor='name'>Name</label>
                <input 
                  type='text'
                  className='form-control'
                  id='name'
                  placeholder='Name'
                  name='name'
                  value={name} 
                  onChange={e => onChange(e)}
                />
              </div>
              <div className='mb-3'>
                <label htmlFor='image'>Image</label>
                <input 
                  type='text'
                  className='form-control'
                  id='image'
                  placeholder='URL for image'
                  name='image'
                  value={image} 
                  onChange={e => onChange(e)}
                />
              </div>

              <div className='d-grid gap-2'>
                <input type='submit' value='Add Feedback' className='btn btn-light '/>
              </div>
            </form>
          </div>
        </div>
    </Fragment>
  )
}

export default AddFeedback
