// use shortcut rafce
// open up bootstrap and check for navbar components
import React from 'react';
// 8.2 we use propTypes to enforce Type checking
// shortcut 'impt'
import PropTypes from 'prop-types';

//  14 import LINK
// change href to 'to'
// change # to /
// change <a> to <Link>
import { Link } from 'react-router-dom';

// import icons
import { FaHome, FaPlus,FaInfoCircle } from 'react-icons/fa';


// 6 add in props as parameter so we can assess props passed into this component
const Header = (props) => {
    // console.log(props)

    // destructure the obj
    // const { branding } = props

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-success">
        <div className="container-fluid">
            <Link className="navbar-brand" to="/">{props.branding}</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to="/"><FaHome/>Home</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/add"><FaPlus/>Add</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/about"><FaInfoCircle/>About</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link disabled">to be confirmed</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

// 7 we can set default props if no props are passed into the component
Header.defaultProps = {
    branding: 'My App'
};

// 8.1 we can use proptypes to check the data being passed through
Header.propTypes = {
    branding: PropTypes.string,
}


export default Header


// components must start with a capital letter
// e5 JS create.feedback -> html tag called feedback